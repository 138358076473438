import React from "react";
import {AppBar, Box, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

interface Props {
    children: React.ReactNode | React.ReactNode[];

}


const Layout = (props: Props) => {
    return <Box display="flex" flexDirection="column" sx={{"height": "100vh"}}>
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Vitalii Pavliuk
                </Typography>
                {/* <GoogleLoginButton /> */}
            </Toolbar>
        </AppBar>
        {props.children}
    </Box>;
}

export default Layout;
