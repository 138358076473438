import React from "react";
import {Avatar, Card, CardActions, CardContent, CardHeader, IconButton, Typography} from "@mui/material";
import {Instagram, LinkedIn, Telegram, GitHub} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

const MyCard = () => {
    const { t } = useTranslation();

    return <Card sx={{margin: { xs: 1, md: 10}}}>
        <CardHeader
            avatar={
            <Avatar src="/vitalii.png" />
        }
            title="Vitalii Pavliuk"

        />
        <CardContent>
            <Typography variant="body1" color="text.primary">
                {t("Hey! I'm Vitalii Pavliuk, a programmer and IT teacher. Welcome to my website! Here you can chat with my Digital Twin, powered by AI.")}
            </Typography>
        </CardContent>
        <CardActions>
            <a href="https://www.linkedin.com/in/vitalii-pavliuk/" target="_blank">
            <IconButton aria-label="linkedin">
                <LinkedIn />
            </IconButton>
            </a>
            <a href="https://instagram.com/vitalii.tech_" target="_blank">
                <IconButton aria-label="intagram">
                    <Instagram />
                </IconButton>
            </a>
            <a href="https://t.me/vitaliitech" target="_blank">
                <IconButton aria-label="telegram">
                    <Telegram />
                </IconButton>
            </a>
            <a href="https://github.com/p1v2" target="_blank">
                <IconButton aria-label="github">
                    <GitHub />
                </IconButton>
            </a>
        </CardActions>
    </Card>
}

export default MyCard;
